.btn {
    background-color: #ffdd61;
    color: #464646;
    border: none;
    border-radius: $round-corner-radius;
    padding: 15px;
    font-weight: 500;
    font-size: $font-size-16;
    width: 100%;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-white;
    }
}
