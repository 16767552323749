// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.ls-gui-element.ls-bar-timer {
    display: none;
}

p {
    font-family: $font-family-2;
    font-weight: normal;
    font-size: $font-size-22;
    color: #5e605f;
    margin: 0;
    line-height: 1.5;
}

/** Replace the width of the container **/
.container {
    width: 1540px;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 5%;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-intro {
    padding: 100px 0;
    h2 {
        font-weight: 300;
        font-size: $font-size-36;
        text-align: center;
        color: #ffdd61;
        padding-bottom: 50px;
    }
    p {
        width: 86%;
        margin: auto;
    }
    a.btn-suite {
        background: #ffdd61;
        display: block;
        width: 10%;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        margin: 50px auto 0 auto;
        h5 {
            font-weight: bold;
            font-size: $font-size-14;
            color: #464646;
            text-transform: uppercase;
        }
        &:hover {
            h5 {
                color: #fff;
                transition: 0.3s;
            }
        }
    }
}

#section-background {
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    min-height: 800px;
    display: flex;
    background-position: right;
    background-size: cover;
    .text-box {
        width: 41%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            font-weight: 300;
            font-size: $font-size-50;
            color: #5e605f;
            text-align: center;
            padding-bottom: 50px;
        }
        p {
            text-align: justify;
        }
    }
}

#section-text-list {
    background: url("../images/accueil_section04_bg.jpg") no-repeat;
    min-height: 794px;
    display: flex;

    .left-side {
        width: 50%;
        margin-right: 10%;
        h3 {
            font-weight: 300;
            font-size: $font-size-40;
            color: #ffdd61;
        }
    }
    .right-side {
        width: 50%;
        ul {
            li {
                font-weight: 300;
                font-size: $font-size-22;
                color: $color-white;
                line-height: 1.5;
                text-transform: initial;
                padding-bottom: 10px;
            }
        }
    }
}

#section-text-burst {
    padding: 75px 0;
    .text-img {
        display: flex;
        padding-bottom: 100px;
        .left-side {
            width: 54%;
            margin-right: 10%;
            padding-top: 50px;
            h3 {
                font-weight: 300;
                font-size: $font-size-50;
                text-align: center;
                color: #ffdd61;
                padding-bottom: 50px;
            }
            a.btn-suite {
                background: #ffdd61;
                display: block;
                width: 25%;
                padding: 20px;
                border-radius: 5px;
                text-align: center;
                margin: 50px auto 0 auto;
                h5 {
                    font-weight: bold;
                    font-size: $font-size-14;
                    color: #464646;
                    text-transform: uppercase;
                    transition: 0.3s;
                }
                &:hover {
                    h5 {
                        color: #fff;
                        transition: 0.3s;
                    }
                }
            }
        }
        .right-side {
            position: relative;
            .overlay {
                background-color: rgba(255, 255, 255, 0.4);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
            &:hover {
                .overlay {
                    opacity: 1;
                }
            }
        }
    }
    .section-burst {
        display: flex;
        a.burst {
            .text {
                min-height: 105px;
                display: flex;
                align-items: center;
                margin-top: -30px;
                position: relative;
                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
                h4 {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: #3c3c3c;
                    text-transform: uppercase;
                    padding-left: 10px;
                }
            }
        }
        a.burst.left {
            .text {
                background: url("../images/accueil_section6_burst01_bg.png") no-repeat; 
            }
            &:hover {
                .text {
                    background: url("../images/accueil_section6_burst01_bg_hover.png") no-repeat;
                    .overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        a.burst.center {
            .text {
                background: url("../images/accueil_section6_burst02_bg.png") no-repeat;
            }
            &:hover {
                .text {
                    background: url("../images/accueil_section6_burst02_bg_hover.png") no-repeat;
                    .overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        a.burst.right {
            .text {
                background: url("../images/accueil_section6_burst03_bg.png") no-repeat;
            }
            &:hover {
                .text {
                    background: url("../images/accueil_section6_burst03_bg_hover.png") no-repeat;
                    .overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

#section-footer {
    background: url("../images/accueil_section06_bg.jpg") no-repeat;
    padding: 50px 0;
    min-height: 135px;
    h4 {
        font-weight: 300;
        font-size: $font-size-20;
        color: #ffdd61;
        text-transform: uppercase;
    }
    .left-side {
        a.icon-info {
            display: flex;
            align-items: center;
            .icon {
                margin-right: 8px;
            }
            .info {
                h5 {
                    font-weight: 300;
                    font-size: $font-size-18;
                    color: $color-white;
                    transition: 0.3s;
                }
            }
            &:hover {
                .info {
                    h5 {
                        color: #eac355;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
    .right-side {
        .img-responsive.hover {
            display: none;
        }
        &:hover {
            .img-responsive.hover {
                display: block;
            }
            .img-responsive.defaut {
                display: none;
            }
        }
    }
}

/*******************************************************************************
* AMÉNAGEMENT PAYSAGER
*******************************************************************************/

#section-text {
    h3 {
        font-weight: 500;
        font-size: $font-size-34;
        color: #ffdd61;
        padding-bottom: 30px;
    }
    h5 {
        font-weight: bold;
        font-size: $font-size-22;
        color: #5e605f;
        text-transform: uppercase;
        padding-bottom: 30px;
        font-family: $font-family-2;
    }
    .section-images {
        padding-top: 50px;
        .image {
            width: 50%;
        }
    }
}

#section-text.text { 
    padding: 10px 0 50px 0;
    p {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-22;
        color: #5e605f;
        margin: 0;
        line-height: 1.5;
    }
}

#section-text.title-text {
    padding: 0 0 0px 0;
}

#section-text-img {
    .left-side {
        width: 50%;
        margin-right: 25px;
    }
    .right-side {
        width: 50%;
    }
    h3 {
        font-weight: 500;
        font-size: $font-size-34;
        color: #ffdd61;
        padding-bottom: 35px;
    }
    h5 {
        font-weight: bold;
        font-size: $font-size-22;
        color: #5e605f;
        text-transform: uppercase;
        font-family: $font-family-2;
    }
    ul {
        li {
            list-style: none;
            position: relative;
            line-height: 1.5;
            font-weight: normal;
            font-size: $font-size-22;
            font-family: $font-family-2;
            color: #5e605f;
            text-transform: initial;
            &:before {
                content: " •";
                color: #ffdd61;
                position: absolute;
                padding-left: 15px;
                left: -40px;
                top: -7px;
                padding-left: 15px;
                font-size: 30px;
            }
        }
    }
}

/*******************************************************************************
* MAINTENANCE
*******************************************************************************/

#section-title {
    padding: 75px 0 0 0;
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: $font-size-36;
        text-transform: uppercase;
        color: #ffdd61;
    }
    h3 {
        font-weight: 500;
        font-size: $font-size-36;
        color: #ffdd61;
    }
}

#section-text-images {
    padding: 75px 0;
    background: #ffdd61;
    h5 {
        font-weight: bold;
        font-size: $font-size-22;
        color: #5e605f;
        text-transform: uppercase;
        padding-bottom: 30px;
        text-align: center;
        font-family: $font-family-2;
    }
    p {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-22;
        color: #5e605f;
        margin: 0;
        line-height: 1.5;
    }
    .section-images {
        padding-top: 50px;
    }
}

/*******************************************************************************
* EMPLOI
*******************************************************************************/

#section-title.emploi {

    h3 {
        font-weight: 500;
        font-size: $font-size-26;
        color: #ffdd61;
        text-transform: initial;
    }
}

#section-text {
    padding: 75px 0;
}

#section-emplois {
    padding: 0 0 50px 0;
    h3 {
        font-family: $font-family-2;
        font-weight: bold;
        font-size: $font-size-26;
        color: #ffdd61;
        padding-bottom: 25px;
    }
    .item-poste.emploi {
        padding: 50px 5%;
        margin-bottom: 50px;
        h4 {
            font-weight: 800;
            font-size: $font-size-24;
            color: #585858;
            padding-bottom: 35px;
            text-transform: uppercase;
        }
        h5 {
            font-weight: 800;
            font-size: $font-size-20;
            color: #585858;
            text-transform: uppercase;
        }
        p, li {
            font-weight: 500;
            font-size: $font-size-20;
            color: #585858;
            margin: 0;
            line-height: 1.3;
        }
        ul {
            padding-top: 15px;
            li {
                font-family: $font-family-2;
                line-height: 1.5;
                padding-bottom: 5px;
                text-transform: initial;
            }
        }
        .btn-emploi {
            margin-top: 25px;
            display: flex;
            justify-content: flex-end;
            .btn {
                width: 15%;
            }  
        }
        &:nth-child(odd) {
            background: #fff8df;
        }
    }
}

section#section-form {
    padding: 0 0 75px 0;
    h3 {
        font-family: $font-family-2;
        font-weight: bold;
        font-size: $font-size-26;
        color: #ffdd61;
        padding-bottom: 20px;
    }
    .form-group {
        margin-bottom: 0;
    }
    .col-xs-12.col-sm-6.left .form-group {
        height: 82px;
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 0;
    .realisations {
        a.item {
            width: 33%;
            padding: 15px;
            .image {
                position: relative;
                img.img-responsive {
                    width: 100%;
                }
                .img-plus {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: 0.5s;
                }
                .overlay {
                    background-color: rgba(0, 0, 0, 0.7);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }
            } 
            &:hover {
                .image {
                    
                    .overlay {
                        opacity: 1;
                    }
                    .img-plus {
                        opacity: 1;
                        transition: 0.5s;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 0 75px 0;
    .left-side {
        width: 50%;
        margin-right: 5%;
        h4 {
            font-weight: 500;
            font-size: $font-size-18;
            color: #ffdd61;
            padding-bottom: 5px;
            text-transform: uppercase;
        }
        h5 {
            font-weight: 500;
            font-size: $font-size-18;
            color: #464646;
            line-height: 1.3;
        }
        i.fa {
            color: #ffdd61;
            font-size: 24px;
        }
        .jours-heures {
            h5 {
                padding-bottom: 5px;
            }
        }
    }
    .right-side {
        width: 50%;
    }
}

.clear-both {
    clear: both;
}

section#section-form\ coordonnees {
    padding-bottom: 75px;
    .form-group {
        height: 70px;
    }
}

#content {
    h3#firstHeading {
        color: #fde381;
    }
    p a {
        color: #464646;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1520px) {
   
#section-text-burst .section-burst a.burst.left .text {
    background: #ff626c;
}
#section-text-burst .section-burst a.burst.left:hover .text {
    background: #ff2c43;
}
#section-text-burst .section-burst a.burst.center .text {
    background: #5ee0fb;
}
#section-text-burst .section-burst a.burst.center:hover .text {
    background: #3edaf7;
}
#section-text-burst .section-burst a.burst.right .text {
    background: #00fca6;
}
#section-text-burst .section-burst a.burst.right:hover .text {
    background: #00f685;
}
#section-text-burst .section-burst a.burst .text {
    min-height: 55px;
    position: relative;
}
#section-text-burst .text-img .left-side a.btn-suite {
    width: 33%;
}
#section-intro a.btn-suite {
    width: 15%;
}
footer .footer-wrapper {
    padding: 0 5%;
}
#section-text-img .container.flex {
    align-items: center;
}
}

@media screen and (max-width: 1390px) {
   
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.8rem;
}
}

@media screen and (max-width: $size-md-max) {
   
#section-title.coordonnees {
    padding: 75px 25px 0 25px;
}
}

@media screen and (max-width: 1200px) {
   
#section-background {
    background: #ffdd61;
    min-height: 500px;
}
#section-background .text-box {
    width: 100%;
}
#section-background .text-box h3 br {
    display: none;
}
#section-background .text-box p {
    text-align: center;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar {
    height: 100px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 150px;
}
nav.module-menu {
    padding-top: 100px;
}
.slideshow-wrap h2.ls-layer {
    font-size: 26px !important;
    position: absolute !important;
    left: -45px !important;
    top: 0 !important;
}
#section-text-list {
    min-height: 500px;
}
#section-text-burst .text-img .left-side h3 {
    font-size: 2rem;
}
#section-text-burst .text-img .left-side a.btn-suite {
    width: 50%;
}
#section-text-img .container.flex {
    flex-direction: column;
}
#section-text-img .left-side {
    width: 100%;
    margin: 0 0 25px 0;
}
#section-text-img .right-side {
    width: 100%;
}
#section-text-img .container.flex .img-responsive {
    margin: auto;
}
#section-text.text {
    padding: 20px 0 35px 0;
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap h2.ls-layer {
    top: -15px !important;
}
#section-intro {
    padding: 65px 0;
}
#section-intro a.btn-suite {
    width: 25%;
}
#section-text-list .container {
    flex-direction: column;
}
#section-text-list .left-side, #section-coordonnees .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
}
#section-text-list .right-side, #section-coordonnees .right-side {
    width: 100%;
}
#section-text-burst .text-img, #section-coordonnees .container {
    flex-direction: column;
}
#section-text-burst .text-img .left-side {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 35px;
}
#section-text-burst .text-img .right-side {
    width: 50%;
    margin: auto;
}
#section-title {
    padding: 65px 25px 0 25px;
}
#section-emplois .item-poste.emploi .btn-emploi .btn {
    width: 25%;
}
#section-realisations .realisations a.item {
    width: 50%;
}
#section-text.last {
    padding: 50px 0 0 0;
}
}

@media screen and (max-width: $size-xs-max) {
 
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 120px;
    margin-left: -20px;
}
nav.module-menu .menu-navbar {
    height: 90px;
}
nav.module-menu {
    padding-top: 90px;
}
.slideshow-wrap h2.ls-layer {
    position: initial !important;
}
nav.module-menu .menu-navbar .wrapper.right {
    padding: 0;
}
#section-intro a.btn-suite {
    width: 35%;
}
#section-text-burst .text-img .right-side {
    width: 85%;
}
#section-text-burst .section-burst {
    flex-direction: column;
}
#section-text-burst .section-burst a.burst.center.px5 {
    padding: 10px 0;
}
#section-footer .flex.justify-between {
    flex-direction: column;
    text-align: center;
}
#section-footer .flex.justify-between .left-side {
    padding-bottom: 25px;
}
#section-footer .left-side h4, #section-footer .right-side h4 {
    font-size: 1.3rem;
    padding-bottom: 15px;
}
footer {
    min-height: 85px;
}
footer .footer-wrapper .copyright {
    margin-bottom: 15px;
}
footer .footer-wrapper {
    justify-content: center;
    flex-direction: column;
}
#section-footer .left-side, #section-footer .right-side {
    flex-direction: column;
}
#section-text-images .section-images {
    flex-direction: column;
}
#section-text-images .section-images .image.mx8 {
    margin: 25px 0;
}
#section-emplois .item-poste.emploi .date-title {
    flex-direction: column;
}
#section-emplois .item-poste.emploi h4 {
    padding-bottom: 10px;
}
#section-emplois .item-poste.emploi .btn-emploi {
    justify-content: center;
}
.section-form .btn-files {
    padding-bottom: 35px;
}
.section-form .form-group.flex.items-center.justify-between {
    flex-direction: column;
}
#section-emplois .item-poste.emploi .date-title .date {
    padding-bottom: 25px;
}
#section-emplois .item-poste.emploi .btn-emploi .btn {
    width: 45%;
}
#section-realisations .realisations a.item {
    width: 100%;
    padding: 15px 0;
}
#section-text-images {
    padding: 75px 0 50px 0;
}
#section-text .section-images {
    flex-direction: column;
}
#section-text .section-images .image {
    width: 100%;
}
#section-text .section-images .image.mr5 {
    margin: 0 0 25px 0;
}
#section-text {
    padding: 75px 0 35px 0;
}
#section-footer .flex.justify-between .left-side .text.mr3 {
    margin-right: 0;
}
section#section-form\ coordonnees .section-btn .form-group.flex.items-center.justify-between {
    margin-top: 40%;
}
}

@media screen and (max-width: 360px) {

.slideshow-wrap h2.ls-layer {
    font-size: 1.8rem !important;
}
}