footer {
    min-height: 55px;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        justify-content: space-between;
        padding: 0 10%;
        .copyright {
            //font-size: 15px;
            color: $color-black;
            font-weight: bold;
        }
        .mediaweb {
            //font-size: 15px;
            a {
                @extend .flex;
                @extend .items-center;
                color: $color-black;
                font-weight: bold;
                align-items: center;
                img {
                    margin: -7px 0 0 0;
                }
                span { padding-left: 5px;}
                &:hover {
                    @extend .text-color-contrast;
                }
            }
        }
        @media screen and (max-width: $size-xs-max) {
            width: $size-footer-width-mobile;
        }
    }
}
